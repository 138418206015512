import { useState } from "react";

export function useMemoryCache(calledAPI: (params: any) => Promise<any>) {
  const [storedValues, setStoredValues] = useState<Record<string, any>>({});

  async function get(queryParam: string): Promise<any> {
    if (!calledAPI) {
      return null;
    }

    if (storedValues[queryParam]) {
      return storedValues[queryParam];
    }

    const returnedValue = await calledAPI(queryParam);
    storedValues[queryParam] = returnedValue;
    setStoredValues(storedValues);
    return returnedValue;
  }

  const memoReturn = {
    get: get,
  };

  return memoReturn;
}
